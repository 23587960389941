import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import { Suspense, lazy, useState, useEffect, useContext } from 'react';
import Context from './Context';

import ScrollToTop from "./Components/ScrollToTop";
import SEOHelper from "./Components/SEOHelper";

import Header from "./Components/Header";
import Footer from "./Components/Footer";
import axios from "axios";
import { numbersOnly } from "./Utils";
import Whatsapp from "./Assets/images/icons/whatsapp.svg";

const Cookies = lazy(() => import('./Components/Cookies'));

const Home = lazy(() => import('./Pages/Home'));
const Empresa = lazy(() => import('./Pages/Empresa'));
const Sac = lazy(() => import('./Pages/Sac'));
const Comprar = lazy(() => import('./Pages/Comprar'));
const Catalogos = lazy(() => import('./Pages/Catalogos'));
const Texto = lazy(() => import('./Pages/Texto'));
const Encontrar = lazy(() => import('./Pages/Encontrar'));
const NotFound = lazy(() => import('./Pages/NotFound'));
const Representantes = lazy(() => import('./Pages/Representantes'));
const Conta = lazy(() => import('./Pages/Conta'));
const Pedidos = lazy(() => import('./Pages/Pedidos'));
const Pedido = lazy(() => import('./Pages/Pedido'));
const Produtos = lazy(() => import('./Pages/Produtos'));
const ListaProdutos = lazy(() => import('./Pages/ListaProdutos'));
const Produto = lazy(() => import('./Pages/Produto'));
const Obrigado = lazy(() => import('./Pages/Obrigado'));
const Obrigadoform = lazy(() => import('./Pages/Obrigadoform'));
const Blog = lazy(() => import('./Pages/Blog'));
const Conteudo = lazy(() => import('./Pages/Conteudo'));
const EditarConta = lazy(() => import('./Pages/EditarConta'));
const Carrinho = lazy(() => import('./Pages/Carrinho'));
const Contato = lazy(() => import('./Pages/Contato'));
const Exclusivas = lazy(() => import('./Pages/Exclusivas'));
const Showroom = lazy(() => import('./Pages/Showroom'));
const LojaFisica = lazy(() => import('./Pages/LojaFisica'));
const SejaRepresentante = lazy(() => import('./Pages/SejaRepresentante'));


function App() {
  // localStorage.setItem("apiUrl", "http://localhost:8080/api/");
  localStorage.setItem("apiUrl", "https://alleanzaceramica.com.br/sistema/api/");
  const [resumo, setResumo] = useState({});
  const [modal, setModal] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [activePage, setActivePage] = useState(false);
  const [cart, setCart] = useState([]);
  const value = { resumo, setResumo, modal, setModal, activePage, setActivePage, cart, setCart, loggedIn, setLoggedIn };

  useEffect(() => {
    axios.get(localStorage.getItem("apiUrl") + "configuracoes").then(response => {
      setResumo(response.data.success);
    });

    let id = localStorage.getItem("id");
    let codigo = localStorage.getItem("codigo");
    if (id && codigo) {
      axios.get(localStorage.getItem("apiUrl") + "check?id=" + id + "&codigo=" + codigo).then(response => {
        if (response.data.status === 200) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      });
    }

    let carrinho = JSON.parse(localStorage.getItem("carrinho"));
    if (!carrinho) {
      carrinho = []
    }
    setCart(carrinho);
  }, []);

  return (
    <HelmetProvider>
      <Context.Provider value={value}>
        <div id="modal" className={modal ? "active" : ""}>
          {modal && <div className="content">
            <h3>{modal.titulo}</h3>
            <p>{modal.texto}</p>
            <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
          </div>}
        </div>
        <Router>
          <Switch>
            <Route path="*">
              <Application />
            </Route>
          </Switch>
        </Router>
      </Context.Provider>
    </HelmetProvider>
  );
}

const Application = () => {
  const { resumo } = useContext(Context);
  let location = useLocation();
  return (
    <div className="App">
      <ScrollToTop />
      {resumo.configuracoes &&
        <>
          <Header />
          <SEOHelper />
          <Suspense fallback={<div className="suspense"></div>}>
            <Switch location={location}>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/empresa" exact>
                <Empresa />
              </Route>
              <Route path="/como-comprar" exact>
                <Comprar />
              </Route>
              <Route path="/onde-comprar" exact>
                <Encontrar />
              </Route>
              <Route path="/nossos-representantes" exact>
                <Representantes />
              </Route>
              <Route path="/minha-conta" exact>
                <Conta />
              </Route>
              <Route path="/minha-conta/:id/:codigo" exact>
                <Conta />
              </Route>
              <Route path="/pedidos" exact>
                <Pedidos />
              </Route>
              <Route path="/pedido/:id" exact>
                <Pedido />
              </Route>
              <Route path="/editar-conta" exact>
                <EditarConta />
              </Route>
              <Route path="/sac" exact>
                <Sac />
              </Route>
              <Route path="/produtos" exact>
                <Produtos />
              </Route>
              <Route path="/pesquisa/:pesquisa" exact>
                <Produtos />
              </Route>
              <Route path="/colecao/:colecao" exact>
                <Produtos />
              </Route>
              <Route path="/colecao/:colecao/categoria/:categoria" exact>
                <Produtos />
              </Route>
              <Route path="/linha/:linha" exact>
                <Produtos />
              </Route>
              <Route path="/linha/:linha/categoria/:categoria" exact>
                <Produtos />
              </Route>
              <Route path="/categoria/:categoria" exact>
                <Produtos />
              </Route>
              <Route path="/lista-produtos" exact>
                <ListaProdutos />
              </Route>
              <Route path="/produto/:slug" exact>
                <Produto />
              </Route>
              <Route path="/obrigado" exact>
                <Obrigado />
              </Route>
              <Route path="/obrigadoform" exact>
                <Obrigadoform />
              </Route>
              <Route path="/blog" exact>
                <Blog />
              </Route>
              <Route path="/post/:slug" exact>
                <Conteudo />
              </Route>
              <Route path="/catalogos" exact>
                <Catalogos />
              </Route>
              <Route path="/carrinho" exact>
                <Carrinho />
              </Route>

              <Route path="/contato" exact>
                <Contato />
              </Route>

              <Route path="/trabalhe-:trabalhe" exact>
                <Contato />
              </Route>

              <Route path="/linhas-exclusivas" exact>
                <Exclusivas />
              </Route>

              {/* <Route path="/showroom" exact>
                <Showroom />
              </Route> */}

              <Route path="/loja-fisica" exact>
                <LojaFisica />
              </Route>
              <Route path="/seja-nosso-representante" exact>
                <SejaRepresentante />
              </Route>

              {resumo.textos &&
                <Route path="/termos-de-uso" exact>
                  <Texto titulo={"Termos de uso"} texto={resumo.textos.termos_de_uso} />
                </Route>
              }
              {resumo.textos &&
                <Route path="/politica-de-privacidade" exact>
                  <Texto titulo={"Política de privacidade"} texto={resumo.textos.politica_de_privacidade} />
                </Route>
              }
              <Route component={NotFound} />
            </Switch>
            <Cookies />
          </Suspense>
          <Footer />
        </>
      }
      {
        resumo.configuracoes && <div itemScope itemType="https://schema.org/LocalBusiness" className="schema">
          <span itemProp="name">{resumo.configuracoes.app_title}</span>
          {resumo.seo.empresa && <span itemProp="image">{resumo.seo.empresa.imagem}</span>}
          <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
            <span itemProp="streetAddress">{resumo.configuracoes.endereco}</span>
            <span itemProp="addressLocality">{resumo.configuracoes.cidade}</span>,
            <span itemProp="addressRegion">{resumo.configuracoes.estado}</span> <span itemProp="postalCode">{resumo.configuracoes.cep}</span>
          </div>
          <span itemProp="telephone">{resumo.configuracoes.telefone}</span>
          <a itemProp="url" href={resumo.configuracoes.url}>{resumo.configuracoes.url}</a>
          <meta itemProp="openingHours" content={resumo.configuracoes.horario} />
        </div>
      }
      {resumo.configuracoes && resumo.configuracoes.whatsapp && <a href={`https://wa.me/55${numbersOnly(resumo.configuracoes.whatsapp)}`} target="_blank" rel="noreferrer noopener" className="floating-whatsapp" title="Fale conosco">
        <img src={Whatsapp} width="48px" height="48px" alt="whatsapp" />
      </a>}
    </div >
  )
}

export default App;
